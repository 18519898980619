<template>
  <div>
    <h3 class="font-21 theme-font-medium py-2">Additional header information <InheritText v-if="multilingualToggle" /></h3>
    <b-form-row>
      <b-col sm="12" md="11" lg="11" xl="10">
        <b-form-group>
          <b-form-row>
            <b-col cols="12" sm="4" v-for="(header, index) in additionalHeaderInfoList" :key="index">
              <b-form-checkbox
                :checked="header.isSelected"
                :v-modal="header.isSelected"
                @input="header.isSelected = $event"
                :id="'additional-header-info-' + index"
                :name="'additional-header-info-' + index"
                @change="setUserSelectedHeaderInfo(header.key, $event)"
                :disabled="multilingualToggle"
                inline
                >{{ header.name }}</b-form-checkbox
              >
            </b-col>
          </b-form-row>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { AdditionalHeaderInfoList } from '@/utilities/widget-constants'
export default {
  name: 'AdditionalHeaderInformation',
  data() {
    return {
      additionalHeaderInfoList: cloneDeep(AdditionalHeaderInfoList),
    }
  },
  props: {
    additionalHeaderInfoFlag: {
      type: Object,
    },
    culture: {
      type: String,
    },
    defaultCulture: {
      type: String,
    },
  },
  components: {
    InheritText: () => import('../InheritText.vue'),
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.culture !== this.defaultCulture
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  mounted() {
    this.assignHeaderInfoData()
  },
  methods: {
    assignHeaderInfoData() {
      this.additionalHeaderInfoList.forEach((header) => {
        header.isSelected = this.additionalHeaderInfoFlag[header.key]
      })
    },
    setUserSelectedHeaderInfo(key, value) {
      this.additionalHeaderInfoFlag[key] = value
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.addUpdateApiTokenForm.tokenName = null
      this.addUpdateApiTokenForm.tokenField = null
    },
  },
}
</script>
